<template>
  <div>
    <HeaderPanel
      title="Product Mobile"
      :filter="filter"
      placeholder="Search Product name"
      @sidebar="sidebarFilter"
      @search="getList"
      :hasDropdown="false"
      routerPath="/productmobile/details/0"
    />
    <div class="bg-white border-red px-1 px-sm-3 pb-3 mt-3">
      <b-row class="mt-3">
        <b-col>
          <b-table
            responsive
            striped
            hover
            :fields="fields"
            :items="items"
            :busy="isBusy"
            show-empty
            empty-text="No matching records found"
          >
            <template v-slot:cell(image_url)="data">
              <div
                v-if="data.item.image_url"
                class="box-image"
                v-bind:style="{
                  'background-image': 'url(' + data.item.image_url + ')',
                }"
              ></div>
            </template>
            <template v-slot:cell(name)="data">
              <div>
                <router-link :to="'/productmobile/details/' + data.item.id">
                  <b-button variant="link" class="name-link">
                    {{ data.item.name }}
                  </b-button>
                </router-link>
              </div>
            </template>
            <template v-slot:cell(status)="data">
              <div v-if="data.item.status == 1" class="text-success">
                Active
              </div>
              <div v-else class="text-danger">Inactive</div>
            </template>
            <template v-slot:cell(action)="data">
              <div class="d-flex justify-content-center">
                <router-link :to="'/productmobile/details/' + data.item.id">
                  <b-button variant="link" class="text-warning px-1 py-0">
                    <font-awesome-icon icon="pencil-alt" title="Edit" />
                  </b-button>
                </router-link>
                <b-button
                  variant="link"
                  class="text-danger px-1 py-0"
                  @click="AlertDelete(data.item.id)"
                >
                  <font-awesome-icon
                    icon="trash-alt"
                    title="Delete"
                    class="main-color"
                  />
                </b-button>
              </div>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <Pagination
        :pageOptions="pageOptions"
        :filter="filter"
        :rows="rows"
        @pagination="pagination"
        @handleChangeTake="handleChangeTake"
      />
    </div>
    <SideBarFilter
      ref="sidebarFilter"
      :filter="filter"
      @searchAll="getDataByStatus"
      placeholder="Search product name"
      @clearFilter="clearFilter"
    >
      <template v-slot:checkbox-option>
        <b-row class="no-gutters mt-3 mb-3">
          <b-col md="12">
            <p class="font-weight-bold mb-2 p-black">Retail Price</p>
          </b-col>
          <b-col md="5">
            <b-form-input type="number" v-model="filter.from_price">
            </b-form-input>
          </b-col>
          <b-col md="2" class="text-center mt-1">
            <p>-</p>
          </b-col>
          <b-col md="5">
            <b-form-input type="number" v-model="filter.to_price">
            </b-form-input>
          </b-col>
        </b-row>
      </template>
    </SideBarFilter>
  </div>
</template>

<script>
import HeaderPanel from "@/components/HeaderPanel";
import Pagination from "@/components/Pagination";
import SideBarFilter from "@/components/SideBarFilter";
export default {
  name: "ProductIndex",
  components: { HeaderPanel, Pagination, SideBarFilter },
  data() {
    return {
      fields: [
        {
          key: "image_url",
          label: "",
          class: "w-200",
        },
        {
          key: "name",
          label: "Product Name",
          class: "w-200",
        },
        {
          key: "price",
          label: "Price",
        },
        {
          key: "skin_suit",
          label: "Skin Type",
          class: "w-200",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "action",
          label: "Action",
        },
      ],
      items: [],
      isBusy: false,
      checkAll: false,
      selectAllCb: false,
      rows: 0,
      showing: 1,
      showingTo: 10,
      checkboxInactive: false,
      checkboxActive: false,
      filter: {
        search: "",
        page: 1,
        take: 10,
        status: [],
        from_price: null,
        to_price: null,
      },
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
    };
  },
  watch: {},
  created: async function () {
    await this.getList();
  },
  methods: {
    getList: async function () {
      this.isBusy = true;
      this.filter.to_price = parseInt(this.filter.to_price);
      this.filter.from_price = parseInt(this.filter.from_price);
      let payload = { ...this.filter };
      // console.log('payload', payload);
      payload.status =
        this.filter.status.length == 1 ? this.filter.status[0] : null;
      await this.$store.dispatch("ActionSearchProduct", payload);
      var data = await this.$store.state.moduleConnectAPI.stateSearchProduct;
      if (data.result == 1) {
        this.items = data.detail.detail;
        this.rows = data.detail.total_count;
        this.isBusy = false;
      }
    },
    handleSearch(e) {
      if (e.type === "keyup") {
        if (e.keyCode === 13) {
          this.filter.page = 1;
          this.getList();
        }
      } else if (e.type === "click") {
        this.filter.page = 1;
        this.getList();
      }
    },
    pagination(value) {
      this.filter.page = value;
      if (value === 1) {
        this.showing = this.filter.page;
        this.showingTo = this.filter.take;
      } else {
        this.showing = value * this.filter.take - this.filter.take;
        this.showingTo = this.showing + this.filter.take;
      }
      this.getList();
    },
    handleChangeTake(take) {
      this.filter.page = 1;
      this.filter.take = take;
      this.showingTo = this.filter.take;
      this.getList();
    },
    getDataByStatus() {
      this.getList();
    },

    clearFilter() {
      this.filter = {
        search: "",
        page: 1,
        from_price: null,
        to_price: null,
        take: 10,
        status: [],
      };
    },
    AlertDelete(id) {
      this.$swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this item!",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.deleteData(id);
        }
      });
    },

    sidebarFilter() {
      this.$refs.sidebarFilter.show();
    },
    deleteData: async function (id) {
      this.isLoadingData = true;
      await this.$store.dispatch("ActionDeleteProduct", id);
      var data = await this.$store.state.moduleConnectAPI.stateDeleteProduct;
      if (data.result == 1) {
        this.isLoadingData = false;
        this.$swal("Your item has been deleted!", {
          icon: "success",
        }).then(function () {
          location.reload();
        });
      }
    },
  },
};
</script>

<style scoped>
.image {
  width: 50%;
  height: 50%;
  padding-top: 42.9%;
  margin-left: 25%;
  background-position: center !important;
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
